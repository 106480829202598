@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --ohnim-gallery-width: 20vw;
    --ohnim-gallery-width-small: 200px;
    --ohnim-gallery-width-medium: 300px;
    --ohnim-gallery-count: 20;
  }
}

@layer components {
  .custom-section {
    @apply max-w-[560px] px-4 lg:max-w-[1280px] lg:px-6 m-auto;
  }

  .custom-section-2 {
    @apply max-w-[632px] px-4 lg:max-w-[1280px] lg:px-6 m-auto;
  }

  .lang-drop-shadow {
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25))
      drop-shadow(8px 12px 16px rgba(0, 0, 0, 0.15));
  }

  /* Transitions */
  .fade-in-up-default {
    transform: translateY(30px);
    opacity: 0;
    transition: transform 0.6s cubic-bezier(0.65, 0, 0.35, 1),
      opacity 0.6s cubic-bezier(0.65, 0, 0.35, 1);
  }

  .fade-in-up {
    transform: translateY(0);
    opacity: 1;
  }

  .fade-in-default {
    opacity: 1;
  }

  /* Hero */
  .hero-bg-gradient {
    background: linear-gradient(180deg, rgba(24, 24, 27, 0) 72.56%, #18181b 100%),
      linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }

  /* ProjectGallery */
  .animate-scroll {
    animation: scroll 60s linear infinite;
  }

  .animate-scroll-medium {
    animation: scroll-medium 60s linear infinite;
  }

  .animate-scroll-small {
    animation: scroll-small 60s linear infinite;
  }

  /* WhyNft */
  .slide-down-default {
    transform: translateY(-50%);
    transition: transform 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  }

  .slide-image-02 {
    transition-delay: 200ms;
  }

  .slide-image-03 {
    transition-delay: 400ms;
  }

  .slide-image-04 {
    transition-delay: 600ms;
  }

  .slide-down {
    transform: translateY(0);
  }

  .slide-down-02 {
    transform: translateY(50%);
    transition: transform 0.4s cubic-bezier(0.61, 1, 0.88, 1) 0.2s;
  }

  /* Milestone */
  .image-fade-in-default {
    @apply opacity-100 lg:opacity-40;
    transition: opacity 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }

  .image-fade-in {
    opacity: 1;
  }

  .info-fade-in-default {
    @apply opacity-100 lg:opacity-0;
    transition: opacity 0.2s cubic-bezier(0.32, 0, 0.67, 0) 0.1s;
  }

  .info-fade-in {
    opacity: 1;
    transition: opacity 0.2s cubic-bezier(0.33, 1, 0.68, 1) 0.2s;
  }

  .item-fade-up-default {
    @apply opacity-0 translate-y-[30px] lg:opacity-100 lg:transform-none;
    transition: transform 0.6s cubic-bezier(0.65, 0, 0.35, 1),
      opacity 0.6s cubic-bezier(0.65, 0, 0.35, 1);
  }

  .item-fade-up {
    @apply translate-y-0 lg:transform-none;
    opacity: 1;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(var(--ohnim-gallery-width) * var(--ohnim-gallery-count) * -1));
  }
}

@keyframes scroll-medium {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc(var(--ohnim-gallery-width-medium) * var(--ohnim-gallery-count) * -1)
    );
  }
}

@keyframes scroll-small {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(var(--ohnim-gallery-width-small) * var(--ohnim-gallery-count) * -1));
  }
}

.break-keep {
  word-break: keep-all;
}
